import React, { useEffect, useState, useRef } from 'react';
import { formatDollarRounded, formatDateInWords } from '../utils/formatters';
import BalanceHeader from './BalanceHeader';

const ColumnView = ({
  selectedNode,
  selectedMonth,
  selectedYears,
  handleNodeClick,
  expandNode,
  onBackToSummary,
  onBackToPrevious,
  startDate,
  endDate,
}) => {
  const containerRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [hasNoSubItems, setHasNoSubItems] = useState(false);
  const [columnDistribution, setColumnDistribution] = useState({ numColumns: 3, itemsPerCol: 0 });
  const [sortConfig, setSortConfig] = useState({ key: 'amount', direction: 'desc' });

  // Organize categories and check if they have sub-items
  useEffect(() => {
    if (!selectedNode?.children) {
      setCategories([]);
      return;
    }

    const sortedCategories = sortItems(selectedNode.children, sortConfig);

    // Check if any category has children
    const anyHasChildren = sortedCategories.some(category => category.children && category.children.length > 0);
    setHasNoSubItems(!anyHasChildren);

    // If no sub-items, use the old distribution system
    if (!anyHasChildren) {
      const items = sortedCategories.map(category => ({
        ...category,
        amount: category.amount || 0,
        level: 'parent'
      }));
      setCategories(items);
      return;
    }

    // Process categories with their sub-items
    const processedCategories = sortedCategories.map(category => ({
      ...category,
      amount: category.amount || 0,
      subItems: category.children 
        ? [...category.children]
            .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
            .map(subcategory => ({
              ...subcategory,
              amount: subcategory.amount || 0
            }))
        : []
    }));

    setCategories(processedCategories);
  }, [selectedNode, selectedMonth, selectedYears, sortConfig]);

  // Calculate column distribution for categories without sub-items
  useEffect(() => {
    if (!hasNoSubItems) return;

    const calculateColumnDistribution = () => {
      if (!containerRef.current) return;

      // Initial column determination based on number of items
      let numColumns;
      let itemsPerCol;

      if (categories.length <= 12) {
        numColumns = 1;
        itemsPerCol = categories.length;
      } else if (categories.length <= 24) {
        numColumns = 2;
        itemsPerCol = Math.ceil(categories.length / numColumns);
      } else {
        // For more than 24 items, use the height-based calculation
        const containerHeight = containerRef.current.clientHeight;
        const headerHeight = 40;
        const baseContentPadding = 200;
        const itemBaseHeight = 48;
        
        // Initial calculation with 3 columns
        numColumns = 3;
        let contentPadding = baseContentPadding;
        let availableHeight = containerHeight - headerHeight - contentPadding;
        let maxItemsPerColumn = Math.floor(availableHeight / (itemBaseHeight + 4));
        itemsPerCol = Math.ceil(categories.length / numColumns);
        
        // Adjust number of columns based on vertical space
        while (itemsPerCol > maxItemsPerColumn && numColumns < 12) {
          numColumns++;
          // Add extra padding when we go beyond 3 columns
          contentPadding = baseContentPadding + (numColumns > 3 ? 48 : 0);
          availableHeight = containerHeight - headerHeight - contentPadding;
          maxItemsPerColumn = Math.floor(availableHeight / (itemBaseHeight + 4));
          itemsPerCol = Math.ceil(categories.length / numColumns);
        }
        
        // Final adjustment if items still don't fit
        if (itemsPerCol > maxItemsPerColumn) {
          numColumns = Math.ceil(categories.length / maxItemsPerColumn);
          // Ensure extra padding is applied if we end up with more than 3 columns
          contentPadding = baseContentPadding + (numColumns > 3 ? 48 : 0);
          availableHeight = containerHeight - headerHeight - contentPadding;
          maxItemsPerColumn = Math.floor(availableHeight / (itemBaseHeight + 4));
          itemsPerCol = Math.ceil(categories.length / numColumns);
        }
      }

      setColumnDistribution({ numColumns, itemsPerCol });
    };

    const currentContainer = containerRef.current;
    const resizeObserver = new ResizeObserver(calculateColumnDistribution);
    setTimeout(calculateColumnDistribution, 0);

    if (currentContainer) {
      resizeObserver.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        resizeObserver.unobserve(currentContainer);
      }
    };
  }, [categories, hasNoSubItems]);

  // Calculate totals using only top-level categories
  const calculateTotals = () => {
    const positiveItems = [];
    const negativeItems = [];

    // Only use top-level categories for calculations if they exist
    if (selectedNode && selectedNode.children) {
      selectedNode.children.forEach((item) => {
        if (item.amount >= 0) {
          positiveItems.push(item);
        } else {
          negativeItems.push(item);
        }
      });
    }

    const income = positiveItems.reduce((sum, item) => sum + item.amount, 0);
    const expenses = negativeItems.reduce((sum, item) => sum + item.amount, 0);
    const balance = income + expenses;

    return { income, expenses, balance };
  };

  const { income, expenses, balance } = calculateTotals();

  const handleItemClick = (item) => {
    handleNodeClick(item);
    expandNode(item);
  };

  const sortItems = (items, config) => {
    if (!items) return [];
    return [...items].sort((a, b) => {
      if (config.key === 'name') {
        const comparison = a.name.localeCompare(b.name);
        return config.direction === 'asc' ? comparison : -comparison;
      } else {
        // For amounts, we want to sort considering the sign
        // In descending order (default):
        // 1. Positive numbers first (highest to lowest)
        // 2. Then negative numbers (smallest negative to largest negative)
        // In ascending order:
        // 1. Negative numbers first (largest negative to smallest negative)
        // 2. Then positive numbers (lowest to highest)
        if (config.direction === 'desc') {
          return b.amount - a.amount;
        } else {
          return a.amount - b.amount;
        }
      }
    });
  };

  const handleSort = (key) => {
    const newConfig = {
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'desc' ? 'asc' : 'desc'
    };
    setSortConfig(newConfig);
    setCategories(prevCategories => {
      if (hasNoSubItems) {
        return sortItems(prevCategories, newConfig);
      } else {
        return prevCategories.map(category => ({
          ...category,
          subItems: sortItems(category.subItems, newConfig)
        }));
      }
    });
  };

  const renderSortArrow = (key) => {
    if (sortConfig.key !== key) {
      return <span className="text-gray-400 ml-2">↕</span>;
    }
    return <span className="text-white ml-2">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>;
  };

  const renderColumnHeader = () => (
    <div className="flex justify-between items-center py-2 px-2 bg-gray-700 text-white font-bold">
      <div 
        onClick={() => handleSort('name')}
        className="cursor-pointer hover:bg-gray-600 px-2 py-1 rounded flex items-center"
      >
        <span>Category</span>
        {renderSortArrow('name')}
      </div>
      <div 
        onClick={() => handleSort('amount')}
        className="cursor-pointer hover:bg-gray-600 px-2 py-1 rounded flex items-center"
      >
        <span>Amount ($)</span>
        {renderSortArrow('amount')}
      </div>
    </div>
  );

  const formatAmount = (amount, isParent) => {
    // Only use $ symbol for parent categories
    if (isParent) {
      return formatDollarRounded(amount);
    }
    // For subcategories, format without $ symbol
    return formatDollarRounded(amount).replace('$', '').trim();
  };

  const renderItem = (item) => {
    const isParent = item.level === 'parent';
    return (
      <div key={`${item.parentName || ''}-${item.name}`}>
        <div
          className="flex justify-between items-center py-2 cursor-pointer hover:bg-gray-100 rounded px-2 min-w-0 transition-colors duration-150 ease-in-out"
          onClick={() => item && handleItemClick(item)}
        >
          <span className={`${isParent ? 'font-bold' : ''} truncate mr-2 flex-1`}>
            {item.name}
          </span>
          <span className={`${isParent ? 'font-bold' : ''} whitespace-nowrap`}>
            {formatAmount(item.amount, isParent)}
          </span>
        </div>
        {isParent && (
          <div className="border-b border-gray-300 my-2"></div>
        )}
      </div>
    );
  };

  const renderColumn = (columnIndex) => {
    if (hasNoSubItems) {
      // Use old distribution system for categories without sub-items
      const startIndex = columnIndex * columnDistribution.itemsPerCol;
      const endIndex = Math.min((columnIndex + 1) * columnDistribution.itemsPerCol, categories.length);
      const columnItems = categories.slice(startIndex, endIndex);
      
      if (columnItems.length === 0) return null;
      
      return columnItems.map(item => renderItem(item));
    }

    // One category per column when there are sub-items
    const category = categories[columnIndex];
    if (!category) return null;

    return (
      <>
        {category.subItems.map(subItem => (
          <div
            key={subItem.name}
            className="flex justify-between items-center py-2 cursor-pointer hover:bg-gray-100 rounded px-2 min-w-0 transition-colors duration-150 ease-in-out"
            onClick={() => subItem && handleItemClick(subItem)}
          >
            <span className="truncate mr-2 flex-1">
              {subItem.name}
            </span>
            <span className="whitespace-nowrap">
              {formatAmount(subItem.amount, false)}
            </span>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center gap-4 mb-6">
        <div className="flex gap-2">
          <button
            onClick={onBackToSummary}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150 ease-in-out"
          >
            Back to Summary
          </button>
          <button
            onClick={onBackToPrevious}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150 ease-in-out"
            disabled={!selectedNode?.parent}
          >
            Back To Previous View
          </button>
        </div>
        <div>
          <h3 className="text-4xl font-bold">
            {selectedNode?.name || ''} - {startDate && endDate ? 
              `From ${formatDateInWords(startDate)} to ${formatDateInWords(endDate)}` : 
              'All Time'}
          </h3>
        </div>
      </div>

      <div className="mb-6">
        <BalanceHeader
          total={balance}
          expenses={expenses}
          income={income}
          isColumnView={true}
        />
      </div>
      
      <div className="flex-1 overflow-x-auto overflow-y-hidden">
        <div className="flex gap-6 h-full" style={{ width: 'max-content' }}>
          {[...Array(hasNoSubItems ? columnDistribution.numColumns : categories.length)].map((_, columnIndex) => (
            <div 
              key={columnIndex} 
              ref={columnIndex === 0 ? containerRef : null}
              className="border rounded-lg bg-white shadow flex flex-col"
              style={{ width: '400px' }}
            >
              <div className="sticky top-0 z-10 rounded-t-lg overflow-hidden shadow-sm">
                {renderColumnHeader()}
              </div>
              {!hasNoSubItems && categories[columnIndex] && (
                <div className="sticky top-[40px] z-10 bg-white px-4 py-2 border-b shadow-sm">
                  <div 
                    className="flex justify-between items-center cursor-pointer hover:bg-gray-100 rounded transition-colors duration-150 ease-in-out"
                    onClick={() => categories[columnIndex] && handleItemClick(categories[columnIndex])}
                  >
                    <span className="font-bold truncate mr-2 flex-1">
                      {categories[columnIndex].name}
                    </span>
                    <span className="font-bold whitespace-nowrap">
                      {formatAmount(categories[columnIndex].amount, true)}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex-1 px-4 py-4 overflow-y-auto">
                {renderColumn(columnIndex)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColumnView;
